.wp-block-embed.type-video > .wp-block-embed__wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wp-block-video video {
  max-width: 720px;
}

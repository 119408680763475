// General structure

.entry-content {
  margin: 48px auto;
  max-width: 720px;
  padding-left: 16px;
  padding-right: 16px;
}

.entry-content > .alignwide,
.wp-block-image.alignwide,
.wp-block-gallery.alignwide {
  width: 100vw;
  max-width: 1100px;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.entry-content > .alignwide > img {
  width: 1100px;
}

.entry-content > .alignfull,
.wp-block-image.alignfull,
.wp-block-gallery.alignfull {
  width: 100vw;
  max-width: 100vw;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.entry-content > .alignfull > img {
  width: 100vw;
}

@media screen and (min-width: 768px) {
  .entry-content > * {
    padding-left: 0px;
    padding-right: 0px;
  }
}

// Columns

.wp-block-columns {
  padding-right: 1rem;
  padding-left: 1rem;
}

// Block separator

.wp-block-separator {
  margin: 3em auto;
  padding: 0;
}

.wp-block-table {
  overflow-x: auto;
  margin: 1.5rem0;
}

.wp-block-table table {
  border-collapse: collapse;
}

.wp-block-table td, .wp-block-table th {
  padding: .5em;
}

// Sticky Footer

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
main {
  flex: 1 0 auto;
}

#site-footer {
  background-color: #f3f4f5;
}

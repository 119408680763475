#comments,
#reply-title {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.commentlist,
.commentlist ul {
  padding-left: 0;
}

.commentlist li {
  padding: 1rem;
}

.comment-meta {
  margin-bottom: 1rem;
}

.comment > .children {
  margin-top: 1rem;
  margin-right: -0.625rem;
  margin-bottom: -1.625rem;
}

/*!
 * b4st v3.0 | Unicence
 * http://simonpadbury.github.io/Codebase/
 */
.author-bio,
.comments-wrap {
  margin: 36px auto;
  max-width: 720px;
  padding-left: 16px;
  padding-right: 16px;
}

.site-header-image {
  display: block;
  margin: auto;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  clear: both;
  display: block;
}

/* Author bio */
.author-bio .avatar {
  border: 1px solid #dee2e6;
  border-radius: 50%;
}

/* Main content */
/* Sidebar */
/***********************/
/*--------------------------------------------------------------
## Cover
--------------------------------------------------------------*/
.wp-block-cover-text p {
  padding: 1.5em 16px;
}

@media screen and (min-width: 768px) {
  .wp-block-cover-text p {
    padding: 1.5em 0;
  }
}

/*--------------------------------------------------------------
## Latest Posts
--------------------------------------------------------------*/
ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
  padding: 0 16px;
}

@media screen and (min-width: 768px) {
  .entry-header,
  .entry-content {
    font-size: calc(1rem + (1.375 - 1) * ((100vw - 768px) / (1200 - 768)));
    font-weight: 300;
    line-height: inherit;
  }
  .entry-header h1 {
    font-size: calc(2.5rem + (3.4375 - 2.5) * ((100vw - 768px) / (1200 - 768)));
  }
}

@media screen and (min-width: 1200px) {
  .entry-header,
  .entry-content {
    font-size: 1.375rem;
  }
  .entry-header h1 {
    font-size: 3.4375rem;
  }
}

.more-link {
  display: block;
}

.wp-block-code {
  padding: 0.5em 1em;
  margin: 0 0 1.5rem;
}

.entry-content ul:not(.blocks-gallery-grid),
.entry-content ol {
  margin: 0 auto 1.5em auto;
  max-width: 720px;
  list-style-position: outside;
}

.entry-content li {
  margin-left: 2.5em;
}

.entry-content ul ul,
.entry-content ol ol,
.entry-content ul ol,
.entry-content ol ul {
  margin: 0 auto;
}

.entry-content ul ul li,
.entry-content ol ol li,
.entry-content ul ol li,
.entry-content ol ul li {
  margin-left: 0;
}

.wp-block-quote {
  margin-left: 16px;
  margin-right: 16px;
  padding: 0;
}

.wp-block-quote > * {
  margin-left: 1rem;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
  margin: 36px auto;
  padding: 0;
}

.wp-block-quote.is-large cite,
.wp-block-quote.is-large footer,
.wp-block-quote.is-style-large cite,
.wp-block-quote.is-style-large footer {
  font-size: 13px;
  font-size: 0.8125rem;
}

@media screen and (min-width: 768px) {
  .wp-block-quote {
    margin: 48px auto;
  }
}

.wp-block-pullquote > p:first-child {
  margin-top: 0;
}

.wp-block-pullquote.alignright {
  margin-left: 2rem;
}

.wp-block-pullquote.alignleft {
  margin-right: 2rem;
}

.entry-content {
  margin: 48px auto;
  max-width: 720px;
  padding-left: 16px;
  padding-right: 16px;
}

.entry-content > .alignwide,
.wp-block-image.alignwide,
.wp-block-gallery.alignwide {
  width: 100vw;
  max-width: 1100px;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.entry-content > .alignwide > img {
  width: 1100px;
}

.entry-content > .alignfull,
.wp-block-image.alignfull,
.wp-block-gallery.alignfull {
  width: 100vw;
  max-width: 100vw;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.entry-content > .alignfull > img {
  width: 100vw;
}

@media screen and (min-width: 768px) {
  .entry-content > * {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.wp-block-columns {
  padding-right: 1rem;
  padding-left: 1rem;
}

.wp-block-separator {
  margin: 3em auto;
  padding: 0;
}

.wp-block-group > .wp-block-group__inner-container > * {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.wp-block-group > .wp-block-group__inner-container > .alignwide {
  width: 100vw;
  max-width: 1100px;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.wp-block-group > .wp-block-group__inner-container > .alignfull {
  width: 100vw;
  max-width: 100vw;
  margin: 1.5em 0 1.5em 50%;
  transform: translateX(-50%);
}

.wp-block-group.has-background > .wp-block-group__inner-container > :first-child {
  margin-top: 0;
}

.wp-block-group.has-background > .wp-block-group__inner-container > .alignfull {
  width: calc( 100% + 60px);
  max-width: calc( 100% + 60px);
  position: relative;
  left: -30px;
}

img,
.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

.wp-block-image img {
  display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
  width: 100%;
}

.wp-block-image.alignfull img {
  width: 100vw;
}

.wp-block-image .alignleft img,
.wp-block-image .alignright img,
.wp-block-image .alignleft figcaption,
.wp-block-image .alignright figcaption {
  max-width: 100%;
  width: 100%;
}

.wp-block-image .alignfull,
.wp-block-gallery.alignfull {
  width: 100vw;
}

.wp-block-image .alignright,
.wp-block-image .alignleft,
.wp-block-gallery.alignright,
.wp-block-gallery.alignleft {
  max-width: calc((720px / 2) - 16px);
}

.wp-block-image .alignright,
.wp-block-gallery.alignright {
  margin-left: 2rem;
}

.wp-block-image .alignleft,
.wp-block-gallery.alignleft {
  margin-right: 2rem;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wp-block-video video {
  max-width: 720px;
}

.wp-block-table {
  overflow-x: auto;
  margin: 1.5rem0;
}

.wp-block-table table {
  border-collapse: collapse;
}

.wp-block-table td, .wp-block-table th {
  padding: .5em;
}

#comments,
#reply-title {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.commentlist,
.commentlist ul {
  padding-left: 0;
}

.commentlist li {
  padding: 1rem;
}

.comment-meta {
  margin-bottom: 1rem;
}

.comment > .children {
  margin-top: 1rem;
  margin-right: -0.625rem;
  margin-bottom: -1.625rem;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

#site-footer {
  background-color: #f3f4f5;
}
